<template>
  <div>
    <b-alert v-if="errored" variant="danger" show>
      <p>{{ _entity.message }}</p>
      <p>
        Please note that some servers don't allow external access via web browsers (e.g., when CORS headers are not
        present).
      </p>
      <p>Errored URL: {{ url }}</p>
      <router-link ref="redirect" :to="$route.query.redirect ? $route.query.redirect : '/'">Go back</router-link>
    </b-alert>
    <b-spinner v-else-if="!loaded" label="Loading..."></b-spinner>
    <div v-else :class="loaded && 'loaded'">
      <NavBar></NavBar>
      <b-breadcrumb :items="breadcrumbs" />
      <b-container>
        <b-row>
          <b-col :md="keywords.length > 0 || license != null ? 8 : 12">
            <h1 class="scroll">{{ catalog.title }}</h1>
            <code pt="0">{{ catalog.id }}</code>
            <p v-if="version">
              <small>Version {{ version }}</small>
            </p>
            <p v-if="paginated">
              <b-alert show>Paginated: Parts of the results have been skipped</b-alert>
            </p>
            <p v-if="hasParams()">
              <b-alert show>Forwarded Query Parameter: {{ listParams() }}</b-alert>
            </p>
            <b-tabs :value="tabIndex" @input="selectTab" @activate-tab="activateTab">
              <b-tab :disabled="!this.hasExternalItems && !this.itemCount" key="items" title="Items">
                <b-table
                  :items="items"
                  :fields="itemFields"
                  :per-page="itemsPerPage"
                  :current-page="currentItemListPage"
                  :sort-compare="sortCompare"
                  :outlined="true"
                  responsive
                  small
                  striped
                >
                  <template slot="cell(link)" slot-scope="data">
                    <router-link :to="data.item.to">{{ data.item.title }}</router-link>
                  </template>
                  <template slot="cell(datatake)" slot-scope="data">
                    <b-button
                      variant="outline-light"
                      size="sm"
                      pill
                      v-on:click="form['datatake'] = data.item.datatake"
                      v-b-tooltip.hover
                      :title="data.item.datatake"
                    >
                      <b-icon icon="bookmark-plus" variant="dark" aria-hidden="true"></b-icon>
                    </b-button>
                  </template>
                  <template slot="cell(abs_orbit_number)" slot-scope="data">
                    <b-button
                      variant="outline-light"
                      size="sm"
                      pill
                      v-on:click="form['abs_orbit_number'] = data.item.abs_orbit_number"
                      v-b-tooltip.hover
                      :title="data.item.abs_orbit_number"
                    >
                      <b-icon icon="bookmark-plus" variant="dark" aria-hidden="true"></b-icon>
                    </b-button>
                  </template>
                  <template slot="cell(stripid)" slot-scope="data">
                    <b-button
                      variant="outline-light"
                      size="sm"
                      pill
                      v-on:click="form['stripid'] = data.item.stripid"
                      v-b-tooltip.hover
                      :title="data.item.stripid"
                    >
                      <b-icon icon="bookmark-plus" variant="dark" aria-hidden="true"></b-icon>
                    </b-button>
                  </template>
                </b-table>
                <b-spinner v-if="loadingNext" label="Loading Next.."></b-spinner>
                <small>
                  <code v-if="!next && !loadingNext"
                    >No more results. A total of <b>{{ this.externalItems.length }}</b> items were found.</code
                  >
                </small>
              </b-tab>
              <b-tab :disabled="!this.childCount" key="sources" title="Sources">
                <template v-for="item in children">
                  <b-card :title="item.id" :sub-title="item.type">
                    <b-card-text>
                      {{ item.description || 'No description available' }}
                    </b-card-text>
                    <b-link :href="item.url" class="card-link">Learn more</b-link>
                  </b-card>
                </template>
              </b-tab>
              <b-tab :disabled="!description" key="infos" title="Infos">
                <p class="mt-3 text-justify" v-if="description" v-html="description" />
                <template v-if="hasStaticAssets" >
                  <div v-for="(assetProp, key) in staticAssets" :key="key">
                    <b-table striped hover small responsive :outlined="true" :items="[assetProp]" :fields="['title', 'description', 'roles', 'type']">
                      <template slot="cell(title)" slot-scope="data">
                        <a v-bind:href="data.item.path" target="_blank">{{data.value}}</a>
                      </template>
                      <template slot="cell(description)" slot-scope="data">
                        <code>{{data.value}}</code>
                      </template>
                      <template slot="cell(roles)" slot-scope="data">
                        <b-badge v-for="v in data.value" :key="v" variant="light" class="pr-2">{{ v }}</b-badge>
                      </template>
                      <template slot="cell(type)" slot-scope="data">
                        <b-badge variant="light" class="pr-2">{{ data.value }}</b-badge>
                      </template>
                    </b-table>
                  </div>
                </template>
              </b-tab>
            </b-tabs>
          </b-col>
          <b-col v-if="keywords.length > 0 || license != null" md="4">
            <b-card class="mb-3">
              <div class="d-flex align-items-center">
                <h3 class="mt-2 mb-2">Filter results</h3>
                <b-spinner v-if="this.searching" small label="Loading..." class="ml-auto"></b-spinner>
              </div>
              <b-form>
                <b-row cols-lg="2" cols-md="1" cols-sm="2">
                  <b-col class="pr-lg-1">
                    <b-form-group :label="'Start Datetime:'" :label-for="'datetimemin'" description="" label-size="sm">
                      <b-input-group :id="'datetimemin'">
                        <b-form-input
                          v-model="form.datetime.min"
                          id="datetimemin-input"
                          type="text"
                          placeholder="YYYY-MM-DD"
                          autocomplete="off"
                          size="sm"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="form.datetime.min"
                            button-only
                            right
                            locale="en-US"
                            aria-controls="datetimemin-input"
                            @context="onMinDatetimeContext"
                            no-flip
                            size="sm"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col class="pl-lg-1">
                    <b-form-group :label="'End Datetime:'" :label-for="'datetimemax'" description="" label-size="sm">
                      <b-input-group :id="'datetimemax'">
                        <b-form-input
                          v-model="form.datetime.max"
                          id="datetimemax-input"
                          type="text"
                          placeholder="YYYY-MM-DD"
                          autocomplete="off"
                          size="sm"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="form.datetime.max"
                            button-only
                            right
                            locale="en-US"
                            aria-controls="datetimemax-input"
                            @context="onMaxDatetimeContext"
                            no-flip
                            size="sm"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-form-group :label="'Source :'" :label-for="'sourceinput'" description="" label-size="sm">
                  <b-form-select
                    :options="sourcesList"
                    v-on:change="selectSource"
                    v-model="form['source']"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  v-if="this.hasCloudCover"
                  :label="'Max Cloud Cover : ' + form['cloudcover'] + '%'"
                  :label-for="'cloudinput'"
                  description=""
                  label-size="sm"
                >
                  <b-form-input
                    type="range"
                    size="sm"
                    min="0"
                    max="100"
                    step=".1"
                    v-model="form['cloudcover']"
                  ></b-form-input>
                </b-form-group>
                <Map
                  v-model="form.geometry"
                  :tools="true"
                  :features="this.featuresCollection"
                  :openid="this.openProductWithId"
                ></Map>
                <b-form-group
                  v-if="this.hasDatatake && !this.isDatatake && !this.isNightfire"
                  :label="'Datatake Id:'"
                  :label-for="'cloudinput'"
                  description=""
                  label-size="sm"
                >
                  <b-form-input type="search" size="sm" v-model="form['datatake']"></b-form-input>
                </b-form-group>

                <b-form-group
                  v-if="this.hasAbsOrbitNumber"
                  :label="'Absolute Orbit Number:'"
                  :label-for="'cloudinput'"
                  description=""
                  label-size="sm"
                >
                  <b-form-input type="search" size="sm" v-model="form['abs_orbit_number']"></b-form-input>
                </b-form-group>
                <b-form-group
                  v-if="this.hasStrip"
                  :label="'Strip Id:'"
                  :label-for="'cloudinput'"
                  description=""
                  label-size="sm"
                >
                  <b-form-input type="search" size="sm" v-model="form['stripid']"></b-form-input>
                </b-form-group>
              </b-form>
            </b-card>
            <b-card class="mb-3">
              <div class="d-flex align-items-center">
                <h3 class="mt-2 mb-2">Search</h3>
              </div>
              <b-form @submit="onItemIdSearch" class="pb-0">
                <b-form-group :label="'Find an item:'" :label-for="'itemid'" description="" label-size="sm">
                  <b-input-group>
                    <b-form-input type="search" v-model="form['itemid']" placeholder="Item ID" size="sm"></b-form-input>
                    <b-input-group-append>
                      <b-button size="sm" type="submit" text="Search" variant="secondary">
                        <b-icon icon="search" aria-hidden="true"></b-icon>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-form>
            </b-card>
            <b-card bg-variant="light">
              <MetadataSidebar
                :summaries="summaries"
                :keywords="keywords"
                :license="license"
                :providers="providers"
                :filters="filters"
                :staticAssets="staticAssets"
              />
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import Leaflet from 'leaflet'
import { mapActions, mapGetters } from 'vuex'
import NavBar from './NavBar.vue'

import common from './common'
import Migrate from '@radiantearth/stac-migrate'
import { fetchUri, getCatalogUrl } from '../util'

// ToDo 3.0: Import tabs lazily
import LinkTab from './LinkTab.vue'
import AssetTab from './AssetTab.vue'
import Map from './Map.vue'

const ITEMS_PER_PAGE = 2000

export default {
  ...common,
  name: 'Collection',
  props: {
    ancestors: {
      type: Array,
      required: true
    },
    path: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    sourceID: {
      type: String,
      required: true
    },
    collectionID: {
      type: String,
      required: true
    },
    endpoint: {
      type: String,
      required: true
    }
  },
  components: {
    LinkTab,
    AssetTab,
    NavBar,
    Map,
    MetadataSidebar: () => import(/* webpackChunkName: "metadata-sidebar" */ './MetadataSidebar.vue')
  },
  data() {
    return {
      externalItemCount: 0,
      externalItemsPerPage: 0,
      externalItemPaging: false,
      childFields: [
        {
          key: 'title',
          label: 'Title',
          sortable: true
        },
        {
          key: 'description',
          label: 'Description',
          sortable: false
        }
      ],
      currentChildPage: 1,
      childrenPerPage: 25, // also applies to collections
      staticItemFields: [
        {
          key: 'link',
          label: 'Title',
          sortable: false // Sorting doesn't work for links
        },
        {
          key: 'dateAcquired',
          label: 'Acquired',
          sortable: true,
          formatter: function (isoDate) {
            if (isoDate != null) {
              const date = new Date(isoDate)
              return !isNaN(date.getTime()) ? date.toISOString().split('T')[0] : ''
            }
            return ''
          }
        }
      ],
      currentItemPage: 1,
      currentItemListPage: 1,
      tabIndex: 0,
      tabsChanged: false,
      paginated: false,
      extraParam: URLSearchParams,
      form: {
        datetime: {
          min: '',
          max: ''
        },
        source: null,
        geometry: null,
        geojson: null,
        cloudcover: 100,
        datatake: null,
        abs_orbit_number: null,
        stripid: null
      },
      searching: false,
      next: null,
      loadingNext: false,
      itemFeatures: null,
      featuresCollection: null
    }
  },
  asyncComputed: {
    externalItems: {
      default: [],
      lazy: true,
      async get() {
        this.searching = true
        try {
          let pathTarget = this.form.geometry ? 'search' : 'items'

          //strip source from itempath
          let itemsPath
          if (this.form.source !== null) {
            itemsPath = new URL(`${this.url}/@/${this.form.source}/${pathTarget}`)
          } else {
            itemsPath = new URL(`${this.url}/${pathTarget}`)
          }

          let date_min = this.form.datetime.min || ''
          let date_max = this.form.datetime.max || ''
          if (date_min || date_max) {
            if (date_min.length == 10 && date_max.length == 10 && date_min <= date_max) {
              itemsPath.searchParams.append('datetime:btw', [date_min, date_max].toString())
              delete this.$route.query['datetime:gte']
              delete this.$route.query['datetime:lte']
              this.$route.query['datetime:btw'] = `${date_min},${date_max}`
            } else if (date_min.length == 10) {
              itemsPath.searchParams.append('datetime:gte', date_min)
              this.$route.query['datetime:gte'] = date_min
            } else if (date_max.length == 10) {
              itemsPath.searchParams.append('datetime:lte', date_max)
              this.$route.query['datetime:lte'] = date_max
            }
          }

          if (this.form.geometry != null && this.form.geometry != '') {
            itemsPath.searchParams.append('geometry:intersects', this.form.geometry)
            this.$route.query['geometry:intersects'] = this.form.geometry
            this.$root.map_geometry = this.form.geometry
          } else if (this.form.geometry == '') {
            delete this.$route.query['geometry:intersects']
            this.$root.map_geometry = ''
          }

          if (this.hasCloudCover && this.form['cloudcover'] < 100) {
            itemsPath.searchParams.append('eo:cloud_cover:lte', this.form['cloudcover'])
            this.$route.query['eo:cloud_cover:lte'] = this.form['cloudcover']
          }

          if (this.hasDatatake && this.form['datatake'] != null && this.form['datatake'] != '') {
            itemsPath.searchParams.append(this.datatakeField + ':eq', this.form['datatake'])
            this.$route.query[this.datatakeField + ':eq'] = this.form['datatake']
          } else if (this.form['datatake'] == '') {
            delete this.$route.query[this.datatakeField + ':eq']
          }

          if (this.hasAbsOrbitNumber && this.form['abs_orbit_number'] != null && this.form['abs_orbit_number'] != '') {
            itemsPath.searchParams.append(this.absOrbitNumberField + ':eq', this.form['abs_orbit_number'])
            this.$route.query[this.absOrbitNumberField + ':eq'] = this.form['abs_orbit_number']
          } else if (this.form['abs_orbit_number'] == '') {
            delete this.$route.query[this.absOrbitNumberField + ':eq']
          }

          if (this.hasStrip && this.form['stripid'] != null && this.form['stripid'] != '') {
            itemsPath.searchParams.append('planet:strip_id:eq', this.form['stripid'].toString())
            this.$route.query['planet:strip_id:eq'] = this.form['stripid']
          } else if (this.form['stripid'] == '') {
            delete this.$route.query['planet:strip_id:eq']
          }

          const extraParam = new URLSearchParams()

          Object.entries(this.$route.query).forEach(([key, value]) => {
            if (key === 'endpoint') {
              return
            }
            if (key.startsWith('pagination')) {
              this.paginated = true
            }
            if (!itemsPath.searchParams.has(key)) {
              itemsPath.searchParams.append(key, value)
              if (!key.startsWith('pagination')) {
                extraParam.append(key, value)
              }
            }
          })

          this.extraParam = extraParam

          itemsPath.searchParams.set('limit', 50)

          const rsp = await fetchUri(`${itemsPath.toString()}`)

          if (!rsp.ok) {
            console.warn(await rsp.text())
            return []
          }

          const items = await rsp.json()

          this.itemFeatures = items.features && items.features.length > 0 ? items.features : null
          this.featuresCollection = items

          if (items.context != null) {
            this.externalItemCount = items.features.length
            this.externalItemsPerPage = 25
            this.externalItemPaging = true
          } else {
            this.externalItemCount = items.features.length
          }
          let next = items.links.find((w) => w['rel'] == 'next')
          this.next = next ? next['href'] : null

          // Add query parameters to current url
          this.addParamsToLocation(this.$route.query)
          this.searching = false
          return this.loadFeatures(items)
        } catch (err) {
          console.warn(err)
          this.searching = false
          return []
        }
      }
    }
  },
  computed: {
    ...common.computed,
    ...mapGetters(['getEntity']),
    _entity() {
      let object = this.getEntity(this.url)
      if (object instanceof Error) {
        return object
      }

      let cloned = JSON.parse(JSON.stringify(object)) // Clone to avoid changing the vuex store, remove once migration is done directly in vuex
      return Migrate.catalog(cloned)
    },
    _description() {
      return this.catalog.description
    },
    _items() {
      return this.links.filter((x) => x.rel === 'item_at')
    },
    _filters() {
      return this.catalog.filters
    },
    _title() {
      return this.catalog.title
    },
    bands() {
      // ToDo: Merge all bands from assets
      return Array.isArray(this.summaries['eo:bands']) ? this.summaries['eo:bands'] : []
    },
    catalog() {
      return this.entity
    },
    childCount() {
      return this.children.length
    },
    filters() {
      return this._filters.map((filter) => {
        return {
          property: filter.property,
          description: filter.description,
          type: filter.type,
          operators: filter.operators
        }
      })
    },
    staticAssets() {
      return this.catalog["static_assets"] ? this.catalog["static_assets"] : {}
    },
    hasStaticAssets() {
      return this.staticAssets && typeof this.staticAssets === 'object' && Object.keys(this.staticAssets).length > 0
    },
    children() {
      // finds available sources
      return this.sources
    },
    sourcesList() {
      // create sources list for select
      const sources = this.children
        .map((child) => {
          return { text: child.id, value: child.id }
        })
        .sort((a, b) => a.value.localeCompare(b.value))
      sources.unshift({ text: 'All', value: null })
      return sources
    },
    hasExternalItems() {
      return true
    },
    itemCount() {
      return this.externalItemCount
    },
    items() {
      const start = (this.currentItemPage - 1) * this.itemsPerPage
      const end = this.currentItemPage * this.itemsPerPage

      if (!this.hasExternalItems) {
        return this._items.map((itemLink, idx) => {
          const itemUrl = this.resolve(itemLink.href, this.url)

          if (idx >= start && idx < end) {
            // dispatch a fetch if item is within the range of items being displayed
            this.load(itemUrl)
          }

          // attempt to load the full item
          const item = this.getEntity(itemUrl)

          if (item != null) {
            return {
              item,
              to: `/browse/collections/${this.collectionID}/items/${item.id}`,
              title: item.properties.title || itemLink.title || item.id || itemLink.href,
              dateAcquired: item.properties.datetime,
              cloudcover: item.properties['eo:cloud_cover'],
              datatake: item.properties[this.datatakeField],
              abs_orbit_number: item.properties[this.absOrbitNumberField],
              stripid: item.properties['planet:strip_id']
            }
          }

          return {
            to: `/browse/collections/${this.collectionID}`,
            title: itemLink.title || itemLink.href,
            url: itemUrl
          }
        })
      }

      return this.externalItems
    },
    itemsPerPage() {
      return ITEMS_PER_PAGE
    },
    summaries() {
      return this.catalog.summaries || {}
    },
    version() {
      return this.catalog.version
    },
    itemFields() {
      if (this.hasCloudCover) {
        this.staticItemFields.push({
          key: 'cloudcover',
          label: 'Cloud Cover',
          sortable: true,
          formatter: function (cover) {
            return cover != null ? cover + '%' : ''
          }
        })
      }
      if (this.hasDatatake && !this.isDatatake && !this.isNightfire) {
        this.staticItemFields.push({
          key: 'datatake',
          label: 'Datatake',
          sortable: true
        })
      }
      if (this.hasAbsOrbitNumber) {
        this.staticItemFields.push({
          key: 'abs_orbit_number',
          label: 'Absolute Orbit Number',
          sortable: true
        })
      }
      if (this.hasStrip) {
        this.staticItemFields.push({
          key: 'stripid',
          label: 'Strip',
          sortable: true
        })
      }
      return this.staticItemFields
    },
    hasCloudCover() {
      return this.filters.some((f) => f.property == 'eo:cloud_cover')
    },
    hasDatatake() {
      return this.filters.some((f) => f.property.endsWith(':datatake_id'))
    },
    isDatatake() {
      // Test if collection is datatake
      return this.catalog?.id.endsWith('datatakes')
    },
    isNightfire() {
      // Test if collection is nighfire
      return this.catalog?.id.endsWith('nightfire')
    },
    datatakeField() {
      if (this.filters.some((f) => f.property.endsWith(':datatake_id'))) {
        return this.filters.find((f) => f.property.endsWith(':datatake_id')).property
      }
      return ''
    },

    hasAbsOrbitNumber() {
      return this.filters.some((f) => f.property.endsWith(':absolute_orbit_number'))
    },
    absOrbitNumberField() {
      if (this.filters.some((f) => f.property.endsWith(':absolute_orbit_number'))) {
        return this.filters.find((f) => f.property.endsWith(':absolute_orbit_number')).property
      }
      return ''
    },
    hasStrip() {
      return this.filters.some((f) => f.property == 'planet:strip_id')
    },
    geometry() {
      return this.form.geometry
    }
  },
  watch: {
    ...common.watch,
    currentChildPage(to, from) {
      if (to !== from) {
        this.updateState({
          cp: to
        })
      }
    },
    currentItemPage(to, from) {
      if (to !== from) {
        this.updateState({
          ip: to
        })
      }
    },
    externalItems() {
      if (!this.tabsChanged && (!this.$route.query.t || this.$route.query.t === '2') && this.externalItems.length > 0) {
        this.selectTab(0)
      }
    },
    geometry(to, from) {
      if (to != from) {
        this.$route.query['geometry:intersects'] = to
      }
    }
  },
  methods: {
    ...common.methods,
    ...mapActions(['load']),
    initialize() {
      this.syncWithQueryState()
    },
    hasParams() {
      return [...this.extraParam.keys()].length > 0
    },
    listParams() {
      return [...this.extraParam.keys()].join(', ')
    },
    selectSource(source) {
      const query = {}

      for (const [key, value] of this.extraParam.entries()) {
        query[key] = value
      }

      if (source) {
        router.push({ name: 'source', params: { collectionID: this.collectionID, sourceID: source }, query })
        return
      }
      router.push({ name: 'items', params: { collectionID: this.collectionID }, query })
    },
    loadFeatures(items) {
      let features = items.features.map((item, idx) => {
        let to = `/browse/collections/${this.collectionID}/items/${item.id}`
        if (this.sourceID) {
          to = `/browse/collections/${this.collectionID}/@/${this.sourceID}/items/${item.id}`
        }
        return {
          item,
          to,
          title: item.properties.title || item.id,
          dateAcquired: item.properties.datetime,
          cloudcover: common.methods.roundFloat(item.properties['eo:cloud_cover'], 2),
          datatake: item.properties[this.datatakeField],
          abs_orbit_number: item.properties[this.absOrbitNumberField],
          stripid: item.properties['planet:strip_id']
        }
      })
      return features
    },
    async loadNext() {
      this.loadingNext = true
      if (!this.next) {
        this.loadingNext = false
        return
      }
      let url = `${new URL(this.endpoint).origin}${this.next}`

      const rsp = await fetchUri(`${url}`)
      if (!rsp.ok) {
        console.warn(await rsp.text())
        return []
      }
      const items = await rsp.json()
      let next = items.links.find((w) => w['rel'] == 'next')
      this.next = next ? next['href'] : null
      this.externalItems.push(...this.loadFeatures(items))
      this.featuresCollection.features.push(...items.features)
      this.featuresCollection = Object.assign({}, this.featuresCollection)
      this.loadingNext = false
    },
    infinitScroll(event) {
      if (this.loadingNext || !this.loaded || this.tabIndex != 0) {
        return
      }
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 50) {
        this.loadNext()
      }
    },
    selectTab(tabIndex) {
      if (typeof tabIndex !== 'number') {
        tabIndex = parseInt(tabIndex, 10)
        if (isNaN(tabIndex)) {
          return
        }
      }
      if (this.tabsChanged && this.tabIndex !== tabIndex) {
        this.updateState({
          t: tabIndex
        })
      }
      this.$nextTick(() => {
        this.tabIndex = tabIndex
      })
    },
    activateTab() {
      this.tabsChanged = true
    },
    syncWithQueryState() {
      this.selectTab(this.$route.query.t)
      this.currentChildPage = Number(this.$route.query.cp) || 1
      this.currentItemPage = Number(this.$route.query.ip) || 1

      // If we have external items, the b-table needs to "stay" on page 1 as
      // the items list only contains the number of items we want to show.
      this.currentItemListPage = this.hasExternalItems ? 1 : this.currentItemPage
    },
    onMinDatetimeContext(ctx) {
      // prevent reload of variable during edition
      if (this.form.datetime.min && this.form.datetime.min.length == 10) {
        this.form.datetime.min = ctx.selectedYMD
      }
    },
    onMaxDatetimeContext(ctx) {
      // prevent reload of variable during edition
      if (this.form.datetime.max && this.form.datetime.max.length == 10) {
        this.form.datetime.max = ctx.selectedYMD
      }
    },
    refreshMapLayers(e) {
      // refresh layers list with available layers
      let layers = []
      this.$refs.map.mapObject.eachLayer((l) => {
        if (l instanceof Leaflet.Rectangle || l instanceof Leaflet.Polygon || l instanceof Leaflet.FeatureGroup) {
          this.$refs.map.mapObject.removeLayer(l)
        }
        if (l === e.layer) {
          this.$refs.map.mapObject.removeLayer(l)
          return
        }
        layers.push(l)
      })
      this.layers = layers
    },
    addParamsToLocation(params) {
      // add parameters to current location path
      let params_list = Object.keys(params)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&')
      let params_string = params_list.length > 0 ? '?' + params_list : params_list
      history.replaceState({}, null, `${this.$route.path}${params_string}`)
    },
    onItemIdSearch(event) {
      event.preventDefault()
      if (this.form.itemid) {
        const link = `/browse/collections/${this.collectionID}/items/${this.form.itemid}`
        this.$router.push(link)
      }
    },
    openProductWithId(id) {
      const endpoint = getCatalogUrl(this.$route)
      let routeData = this.$router.resolve({
        name: 'item',
        params: {
          collectionID: this.collectionID,
          itemID: id
        },
        query: {
          endpoint: encodeURIComponent(endpoint)
        }
      })

      if (this.sourceID) {
        routeData = this.$router.resolve({
          name: 'sourceItem',
          params: {
            collectionID: this.collectionID,
            itemID: id,
            sourceID: this.sourceID
          },
          query: {
            endpoint: encodeURIComponent(endpoint)
          }
        })
      }

      window.open(routeData.href, '_blank')
    }
  },
  mounted() {
    if (this.$route.query['datetime:btw']) {
      const d = this.$route.query['datetime:btw']
      const [min, max] = d.split(',')
      this.form.datetime.min = min
      this.form.datetime.max = max
    }
    if (this.$route.query['datetime:gte']) {
      const d = this.$route.query['datetime:gte']
      this.form.datetime.min = d
    }
    if (this.$route.query['datetime:lte']) {
      const d = this.$route.query['datetime:lte']
      this.form.datetime.max = d
    }
    this.form.source = this.sourceID || null
    this.form.geometry = this.$route.query['geometry:intersects'] || null
    this.form.cloudcover = this.$route.query['eo:cloud_cover:lte'] || 100
    this.form.datatake = this.$route.query[this.datatakeField + ':eq'] || null
    this.form.abs_orbit_number = this.$route.query[this.absOrbitNumberField + ':eq'] || null

    this.form.stripid = this.$route.query['planet:strip_id:eq'] || null
  },
  created() {
    window.addEventListener('scroll', this.infinitScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.infinitScroll)
  }
}
</script>

<style src="./base.css"></style>
<style lang="css">
label {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
</style>
<style scoped lang="css">
h3 {
  margin-top: 25px;
  margin-bottom: 25px;
}

th h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  text-transform: uppercase;
  color: #555;
  font-weight: normal;
}

.table th {
  border-top: none;
  border-bottom: 1px solid #dee2e6;
}

td.provider {
  border: none;
  padding: 0 15px;
}

td.provider .description {
  padding-left: 5px;
  font-style: italic;
}

#locator-map {
  height: 200px;
  width: 100%;
  margin-bottom: 10px;
}

.datatake, .abs_orbit_number {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.badge {
  font-weight: 200;
}

</style>
